import React, { Component } from 'react'
import { css } from '@emotion/core';

import './startup.css';

import Stage from '../components/stage';
import Layout from '../components/BSLayout';

import DesktopNavbar from '../components/DesktopNavbar';
import MobileNavbar from '../components/MobileNavbar';
import { MinimalFooter } from '../components/Footer';
import Helmet from 'react-helmet';

const mainContent = css`
    padding-top: 0px;
    padding-bottom: 2rem;
    min-height:90vh;
`

const cssContactHero = css`
    padding: 2.5rem 0;
`

const cssPageTitle = css`
    font-size: 4rem;
`

const cssPageSubTitle = css`
    color: rgba(13,17,43,.7);
`
const cssPageText = css`
    text-align: center;
`

const AboutHero = (props) => (
    <div>
        <div css={cssContactHero} className="text-center">
            <h1 css={cssPageTitle} className="h1">About us</h1>
            <h3 css={cssPageSubTitle} className="h3">A truely intelligent smart city</h3>
        </div>
    </div>
);

const AboutUs = (props) =>(
<div>
    <p css={cssPageText}>Our mission is to create intelligent environments that cultivate connected, happy communities..</p>
</div>
);


const HeadData = () => (
  <Helmet>
    <title>About us - Castellan</title>
    <meta name="description" content="About Castellan" />
    <meta name="keywords" content="smart city, smart apartment, smart condominium, luxury living experience, about" />
  </Helmet>
)

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }
  navToggle = () => {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen});
  }

  render() {
    const { menuOpen } = this.state;
    return (
        <Layout>
          <HeadData />
            <div>
                <MobileNavbar />
            <Stage isOpen={menuOpen}>
                <DesktopNavbar onNavToggle={this.navToggle} />
                <div css={mainContent} >
                    <div>
                            <AboutHero />
                    </div>
                    <div>
                        <div className="container">
                            <AboutUs />
                        </div>
                    </div>
                </div>
                <div>
                    <MinimalFooter />
                </div>
            </Stage>
            </div>
        </Layout>
    )
}
}
export default Page;
